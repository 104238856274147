import { News } from "./news";
import { Auth } from "./auth";
import { useEffect, useState } from "react";
import {NavLink, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import {useGlobal} from 'reactn';
import { Files } from "./files";
import logo from './logo.png'
import logo2 from './nalogo_gimp_r.png'
import { Autocomplete, Badge, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Snackbar, Switch, Tabs, TextField, Typography } from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import { AlternateEmailRounded, CheckBox, Logout, Mail, MailOutlineOutlined, MailRounded, ManageAccounts, Settings } from "@mui/icons-material";
import { AuthProvider } from "./autprovider";
import { ManagePortal } from "./manage";
import { MenuDing } from "./menu";
import { OneFile } from "./onefile";
import './nahs.css';
import { format,formatISO } from "date-fns";
import Marquee from "react-fast-marquee";
import { Reports } from "./reports";
import { register, unregister } from "./serviceWorkerRegistration";
import { useServiceWorker } from "./useServiceWorker";
import { subscribeUser } from "./push";

const version = 1.83


function App() {
  const [isAdmin,setIsAdmin] = useGlobal('admin')
  const [lastlogin,setLastlogin] = useGlobal('lastlogin')
  const [toggletasks,setToggletasks] = useGlobal('tasks')
  const [showas,setShowas] = useGlobal('showas')
  const [userliste,setUserliste] = useState([])
  const [axios] = useGlobal('axios')
  const [orgastuff,setOrgastuff] = useState()
  const [maildialog,setMaildialog] = useState(false)
  const [index,setIndex] = useState(0)
  const [status,setStatus] = useState('')
  const [marquee,setMarquee] = useState([])
  const {waitingWorker, showReload, reloadPage} = useServiceWorker()
  const [hassubscription,setHassubscription] = useState(true)
  const currentlocation = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setLastlogin({blog:0,fbnews:0,files:0,lastlogin:formatISO(new Date())})
    if (currentlocation.pathname == '/')
      navigate('blog')
  },[])

  useEffect(() => {
    console.log('SW',waitingWorker,showReload)
  },[waitingWorker,showReload,reloadPage])

  useEffect(() => {
    if (showas) {
      getstatus()
      getmarquee()
    }
    console.log('showas',showas)
  },[showas])

  useEffect(() => {
    if (isAdmin) {
      axios.get('admincheck').then(data => setOrgastuff(data.data))
    }
    if (axios) {
      getstatus()
      getmarquee()
      checkversion()
      getLastlogin()
      checksubscription()
      //setTimeout(e => {setAskfornotification(!(Notification.permission !== "granted"))},5000)
    }
  },[isAdmin,toggletasks])

  const checkversion = () => {
    axios.post('versioncheck',{version:version}).then(result => 
      {console.log('frontend',version,'backend',result.data.backend,'reload',result.data.reload)
      if (result.data.reload) {
        //forced_reload()
        console.log('no manual reload')
      }}
    )
  }

  const checksubscription = () => {
    navigator.serviceWorker.ready.then(function(registration){
        if (!registration.pushManager) {
            console.log('Push manager unavailable.')
            setHassubscription(true)
          }
        registration.pushManager.getSubscription().then(function(existedSubscription) {
            console.log('Subscrptions',existedSubscription,'val',!!existedSubscription)
            //if (!existedSubscription) {
            //  setHassubscription(false)
            //}
            setHassubscription(!!existedSubscription)
        })
    })
}


const subscribe = () => {
    axios.get('subscription').then(data => {
        console.log('vapidkey',data.data.public_key)
        localStorage.setItem('applicationServerPublicKey',data.data.public_key)
        setHassubscription(true)
        subscribeUser(axios,data.data.public_key)
    })
}


  const getLastlogin = () => {
    axios.get('newcontent').then(result => {
      setLastlogin(result.data)
      console.log('Lastlogin',result.data)
    })
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        console.log('clean cache name',name)
        caches.delete(name);
      });
    });
  };

  const forced_reload = () => {
    clearCacheData();
    unregister();
    window.location.reload(true);
  }


  const reset_page = () => {
    axios.get('admincheck').then(data => setOrgastuff(data.data))
    navigate('/blog')
  }

  const getmarquee = ()  => {axios.get('getmarquee').then(data => setMarquee(data.data))}

  const getstatus = () => {axios.get('checkbeschstatus').then(data => setStatus(data.data))}

  const navLinkStyles = ({ isActive }) => {
    return {
      color:'black',
      fontWeight: isActive ? "bold" : "normal",
      textDecoration: "none",
      zoom:'2',
      margin: '5px'

    };
  };

  const formatDate = (datum) => {
    try {
      return format(new Date(datum),'d.M.y')
    } catch {
      return '???'
    }
  }
  

  return (
    <Auth>
      <div className="App">
        <div className={"head " + (isMobile ? ' mobile' : '' )}>
          <img src={logo2} className='logo' onClick={e => reset_page()}/>
          <div className="topright">
              {/* {axios && <MenuDing/>} */}
              {isAdmin && orgastuff && orgastuff.userliste &&
                   <Autocomplete options={orgastuff.userliste} getOptionLabel={(o) => o.user} filterSelectedOptions
                    onChange={(event, newValue) => {setShowas(newValue);}} autoSelect disabled={showas}
                    value={showas}
                    renderInput={(params) => (<TextField {...params} variant="filled" sx={{width:'200px',marginRight:'30px'}} label="Zeige aus Sicht von"/>)}
                   />
              
              }
              <MenuDing version={version}/>
          </div>
          <div className={'shortmenu ' + (isMobile ? ' smmobile': '')} >
            {/* <Button className='menubutton' variant={index === 0 ? 'contained' : 'outlined'} onClick={e => navigate('blog')}>Blog</Button>
            <Button className='menubutton' variant={index === 1 ? 'contained' : 'outlined'} onClick={e => navigate('files')}>Dateien</Button>
          {isAdmin && <Button className='menubutton' variant={index === 1 ? 'contained' : 'outlined'} onClick={e => navigate('manage')}>Verwaltung</Button>} */}
           <Badge color={'error'} variant='dot' anchorOrigin={{vertical: 'top',horizontal: 'left',}} overlap="circular" invisible={!lastlogin?.blog}><NavLink style={navLinkStyles} to='/blog'>Blog</NavLink></Badge>
           <Badge color={'error'} variant='dot' anchorOrigin={{vertical: 'top',horizontal: 'left',}}  overlap="circular" invisible={!lastlogin?.fbnews}><NavLink style={navLinkStyles} to='/files'>Dateien</NavLink></Badge>
           <Badge color={'error'} variant='dot' anchorOrigin={{vertical: 'top',horizontal: 'left',}} overlap="circular" invisible={!lastlogin?.files}><NavLink style={navLinkStyles} to='/fortbildungen'>Fortbildungen</NavLink></Badge>
            {isAdmin && !showas && <NavLink style={navLinkStyles} to='/manage'>Verwaltung</NavLink>}
          </div>
        </div>
        {marquee.length > 0 && 
          <Marquee speed={40} className={isMobile ? 'marqueesmall' : 'marquee'} gradient={false}>
            {marquee.filter(m => m.active).map((m,i) => <Typography variant='h3' color={m?.color || '#eeff00'}style={{marginLeft:'30px'}}>{m.text} &nbsp;&nbsp;&nbsp;</Typography>)}
          </Marquee>
        }
          {!showas && orgastuff && 
          <Container fixed width='lg' className='tasks'>
            {orgastuff.user > 0 && <Box className='infostripe' onClick={e => navigate('manage/user')}><Typography variant='h6'> Es liegen <b>{orgastuff.user}</b> neue Anmeldungen vor</Typography></Box>}
            {orgastuff.cme > 0 && <Box className='infostripe' onClick={e => navigate('manage/cme')}><Typography variant='h6'> Es liegen <b>{orgastuff.cme}</b> neue Bescheinigungen vor</Typography></Box>}
            </Container>}
          {
            status && status.pre.missing &&
              <Container fixed width='lg' className='tasks'>
                <Box className='infostripe user' style={status.pre.warning} onClick={e => navigate('/fortbildungen')}><Typography variant='h6'>Es wird noch der Nachweis von <b>{(20-status.pre.punkte) || '?'}</b> Fortbildungspunkten bis {formatDate(status.pre.ende)} benötigt.</Typography></Box>
              </Container>           
            
          }
          {
            (status && !status.pre.missing && status.akt.ende && status.akt.punkte < 20) && 
              <Container fixed width='lg' className='tasks'>
                  <Box className='infostripe user' style={status.akt.warning} onClick={e => navigate('/fortbildungen')} ><Typography variant='h6'>Es werden noch <b>{(20-status.akt.punkte) || '?'}</b> Fortbildungspunkte bis zum {formatDate(status.akt.ende)} benötigt.</Typography></Box>
            </Container>
          }

        <Routes>
            <Route path="blog" element={<News/>}/>
            <Route path="files" element={<Files/>}>
              <Route path=":file" element={<OneFile/>}/>
            </Route>
              <Route path="fortbildungen" element={<Reports/>}/>
            {isAdmin && !showas && <Route path='manage' element={<ManagePortal/>}>
                <Route path=':page' element={<ManagePortal/>}/>
              </Route>
             }
            <Route path="*" element={<News/>}/>
        </Routes>
        <AlternateEmailRounded sx={{ fontSize: 40, color:'yellow' }} className="maildialogbutton" onClick={e => setMaildialog(true)}/>
        <AelrdMail  open={maildialog} close={e => setMaildialog(false)}/>
      </div>
      <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} 
          message="Es ist eine neue App-Version verfügbar. Zum updaten hier klicken"           
          onClick={e => reloadPage()} open={showReload && waitingWorker}/>
       <Snackbar anchorOrigin={{vertical:'bottom',horizontal:'center'}} 
          message="Darf die Seite ihnen Benachrichtungen schicken?"           
          onClick={e => subscribe()} open={!hassubscription}/>
    </Auth>

  );
}

const NoMatch = () => {
  return <div>Page not found</div>;
};




function AelrdMail({open,close}) {
  const [axios] = useGlobal('axios')
  const [getcopy,setGetcopy] = useState(true)
  const [subject,setSubject] = useState('')
  const [text,setText] = useState('')
  const [mailsent,setMailsent] = useState(false)
  const [aelrd,setAelrd] = useState(true)



  const send = () => {
    axios.post('aelrdmail',{subject:subject,text:text,getcopy:getcopy,aelrd:aelrd}).then(res => {
      setMailsent(true)
      setText('')
      setSubject('')
      close()
    })
  }




  return (
    <Dialog open={open} PaperProps={{className:'aelrdmail'}}>
      <DialogTitle>Nachricht an <br/> 
      <Button variant={aelrd ? 'contained' : 'outlined'} onClick={e => setAelrd(true)} >ärztliche Leitung</Button>
      <Button variant={!aelrd ? 'contained' : 'outlined'} onClick={e => setAelrd(false)} >Technik</Button>
      </DialogTitle>
      <DialogContent>
        <TextField sx={{marginTop:'10px'}} fullWidth label={subject ? 'Betreff' : 'Betreff (bitte ausfüllen)' } error={!subject} value={subject} onChange={e => setSubject(e.target.value)} />
        <TextField sx={{marginTop:'10px'}} label={text ? 'Ihre Nachricht' : 'Ihre Nachricht (bitte ausfüllen)'}  error={!text} fullWidth multiline rows={10} value={text} onChange={e => setText(e.target.value)} />
        <FormControlLabel label='Kopie an mich' control={<Switch onClick={e => setGetcopy(!getcopy)} checked={getcopy} />} />
      </DialogContent>
        <DialogActions>
          <Button onClick={e=> close()} variant='outlined'>abbrechen</Button>
          <Button disabled={!subject || !text} onClick={e=> send()} variant='contained'>absenden</Button>
        </DialogActions>
        <Snackbar open={mailsent} autoHideDuration={3000} onClose={e => setMailsent(false)} message="Mail gesendet"/>
    </Dialog>
  )

}



export default App;



